
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { userFullResource } from "@/api/users.api";
import DashBox from "@/components/cards/DashBox.vue";

export default defineComponent({
  name: 'UserProfile',
  components: {DashBox},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isAdmin = computed(() => store.getters.user.is_admin);
    const userId = +route.params.id;
    let user: any = ref<any>({});
    let detail: any = ref<any>({});
    let stats: any = ref<any>({});

    async function resources() {
      console.log('entro')
      if (!userId) return;

      const response: any = await userFullResource(userId);
      user.value = response.data;

      if (user.value) {
        detail.value = response.data?.detail;
        stats.value = response.data?.resources;
      }
    }


    onMounted(() => {
      if (!isAdmin) {
        router.push({ name: 'Dashboard' });
      }

      resources();
    });

    return {
      userId,
      isAdmin,
      user,
      detail,
      stats,
      userData: computed(() => Object.keys(user).length > 0)
    }
  }
})
