<template>
  <div class="dash-box bg-blue text-white p-3 shadow-lg">
    <div class="d-flex justify-content-center align-items-center text-center h-100 flex-column">
      <div>
        <i :class="`bi ${bootstrapIcon}`"></i>
      </div>
      <h5 class="text-uppercase mb-3">
        {{ title }}
      </h5>
      <p class="stat">
        {{ stat }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashBox",
  props: {
    stat: Number,
    title: String,
    bootstrapIcon: String
  }
}
</script>

<style scoped>
  .dash-box {
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 0.6rem;
    border: 1px solid #bfbfbf;
  }

  .stat {
    font-size: 1.8rem;
  }

  i {
    font-size: 4rem;
  }
</style>