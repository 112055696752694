<template>
  <div v-if="isAdmin && userId && userData">
    <form class="">
      <div class="row g-4">
        <div class="col-md-4">
          <DashBox
            title="Documenti caricati"
            :stat="stats.documents"
            bootstrap-icon="bi-file-check"
          />
        </div>
        <div class="col-md-4">
          <DashBox
              title="Pratiche Totali"
              :stat="stats.practices"
              bootstrap-icon="bi-folder"
          />
        </div>
        <div class="col-md-4">
          <DashBox
              title="Pratiche Chiuse"
              :stat="stats.practices_closed"
              bootstrap-icon="bi-folder-check"
          />
        </div>
        <div class="mb-4"></div>
        <div>
          <h1 class="mt-4 mb-2 text-uppercase fw-bolder">
            Informazioni di {{ `${user.name} ${user.surname}`}}
          </h1>
        </div>
        <div class="col-md-2">
          <label for="id">
            ID
          </label>
          <input id="id" v-model="user.id" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-5">
          <label for="name">
            Nome
          </label>
          <input id="name" v-model="user.name" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-5">
          <label for="surname">
            Cognome
          </label>
          <input id="surname" v-model="user.surname" type="text" class="form-control" disabled />
        </div>
        <div class="col-12">
          <label for="email">
            Email
          </label>
          <input id="email" v-model="user.email" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-6">
          <label for="email-verified">
            Email verificata
          </label>
          <input id="email-verified" v-model="user.email_verified_at" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-6">
          <label for="created-at">
            Iscritto Il
          </label>
          <input id="created-at" v-model="user.created_at" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-4">

          <div class="form-check form-switch flex-column">
            <label for="admin">
              Admin
            </label>
            <input v-model="user.is_admin" class="form-check-input" type="checkbox" id="admin">
          </div>
        </div>
        <div>
          <h1 class="mt-4 mb-2 text-uppercase fw-bolder">
            Dettagli
          </h1>
        </div>

        <div class="col-md-12">
          <label for="business">
            Ragione sociale
          </label>
          <input id="business" v-model="detail.business_name" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-12">
          <label for="cf">
            Codice Fiscale
          </label>
          <input id="cf" v-model="detail.cf" type="text" class="form-control" disabled />
        </div>
        <div class="col-12">
          <label for="piva">
            P.IVA
          </label>
          <input id="piva" v-model="detail.p_iva" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-6">
          <label for="address">
            Indirizzo
          </label>
          <input id="address" v-model="detail.address" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-2">
          <label for="province">
            Provincia
          </label>
          <input id="province" v-model="detail.province" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-4">
          <label for="city">
            Città
          </label>
          <input id="city" v-model="detail.city" type="text" class="form-control" disabled />
        </div>
        <div class="col-md-2">
          <label for="cap">
            Cap
          </label>
          <input id="cap" v-model="detail.cap" type="text" class="form-control" disabled />
        </div>-
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { userFullResource } from "@/api/users.api";
import DashBox from "@/components/cards/DashBox.vue";

export default defineComponent({
  name: 'UserProfile',
  components: {DashBox},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isAdmin = computed(() => store.getters.user.is_admin);
    const userId = +route.params.id;
    let user: any = ref<any>({});
    let detail: any = ref<any>({});
    let stats: any = ref<any>({});

    async function resources() {
      console.log('entro')
      if (!userId) return;

      const response: any = await userFullResource(userId);
      user.value = response.data;

      if (user.value) {
        detail.value = response.data?.detail;
        stats.value = response.data?.resources;
      }
    }


    onMounted(() => {
      if (!isAdmin) {
        router.push({ name: 'Dashboard' });
      }

      resources();
    });

    return {
      userId,
      isAdmin,
      user,
      detail,
      stats,
      userData: computed(() => Object.keys(user).length > 0)
    }
  }
})
</script>

<style scoped>
  button,
  .form-select,
  .form-control {
    border-radius: 22px;
  }

  label {
    font-weight: 600;
    margin-left: 0.8rem;
    color: #173759;
    text-transform: uppercase;
  }
</style>